var assets = require.context('../../node_modules/jquery-ui/themes/base/images/', true, /\.(png|jpg|jpeg|gif|svg|woff|woff2|ttf|eot)$/);
assets.keys().forEach(assets);

// Styles
import '../../node_modules/noty/lib/noty.css';
import '../../node_modules/ng-dialog/css/ngDialog.min.css';
import '../../node_modules/ng-dialog/css/ngDialog-theme-default.min.css';
import '../../node_modules/jsonformatter/dist/json-formatter.min.css';
import '../../node_modules/jquery-ui/themes/base/theme.css';
import '../../node_modules/@progress/kendo-theme-bootstrap/dist/all.css';
import '../../node_modules/@imaginelearning/web-ui-react/dist/assets/scss/base.scss';
import '../../node_modules/@imaginelearning/web-reporting/src/scss/base.scss';
import '../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css';

// jQuery
import '../../node_modules/jquery/dist/jquery.min.js';
import '../../node_modules/jquery-ui-dist/jquery-ui.min.js';
import '../../node_modules/jquery-ui/ui/position.js';
import '../../node_modules/jquery.cookie/jquery.cookie.js';

// Angular
import 'angular';
import '@uirouter/angularjs';
import 'angular-animate';
import 'angular-cookies';
import 'angular-equalizer';
import 'angular-filter';
import 'angular-camelize-filter';
import 'angular-jwt';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-toarrayfilter';
import 'angular-translate';
import 'angular-ui-date';
import 'angular-ui-sortable';
import 'angular-uuid';
import 'angular-validation/dist/angular-validation.js';
import 'angular-validation/dist/angular-validation-rule.js';
import 'ng-dialog';
import 'ngstorage';
import 'ngReact';

import './config.module';

// Everything else
import 'lodash';
import 'animate.css';
import 'html5shiv';
import 'js-xlsx';
import 'jsonformatter';
import 'underscore.string';
